import { start, registerApplication } from 'single-spa'
import 'babel-polyfill'
import { Modules } from './enums/Modules'
import { ContractInfo } from 'modules/confirmation/Confirmation'

declare global {
  interface Window {
    System: any
    singleSpaNavigate: any
  }
}

const CLIENT_ID = 5999 // 336496

const matchRoute = (route: string) => {
  return (location: Location) => location.pathname.includes(`${route}`)
}

registerApplication(
  Modules.COMMON_APP_NAME,
  () => window.System.import(`/salary-advance/${Modules.COMMON_APP_NAME}.js`),
  () => true,
  {
    api: 'DEXTER',
    language: 'ptBr',
  },
)

registerApplication(
  Modules.OVERVIEW_APP_NAME,
  () => window.System.import(`/salary-advance/${Modules.OVERVIEW_APP_NAME}.js`),
  matchRoute('/overview'),
  {
    api: 'DEXTER',
    language: 'ptBr',
    authToken: 'TO_SEM_TOKEN',
    clientId: CLIENT_ID,
  },
)

registerApplication(
  Modules.CONFIRMATION_APP_NAME,
  () =>
    window.System.import(`/salary-advance/${Modules.CONFIRMATION_APP_NAME}.js`),
  matchRoute('/confirmation'),
  {
    api: 'DEXTER',
    language: 'ptBr',
    authToken: 'TO_SEM_TOKEN',
    clientId: CLIENT_ID,
    onLoad: (contractInfo: ContractInfo) => {
      console.log('confirmation module has load', contractInfo)
    },
  },
)

registerApplication(
  Modules.CHART_APP_NAME,
  () => window.System.import(`/salary-advance/${Modules.CHART_APP_NAME}.js`),
  matchRoute('/chart'),
  {
    api: 'DEXTER',
    language: 'ptBr',
    authToken: 'TO_SEM_TOKEN',
    clientId: CLIENT_ID,
    handleAdvance: () => {
      console.log('on advance OK')
    },
  },
)

start()
